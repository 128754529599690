<template>
    <div :class="dialog ? '' : 'mb-14'">
        <v-app-bar
            id="header"
            absolute
            color="white"
            max-width="1160px"
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
        >
            <v-toolbar-title class="link"
                ><img :src="require('../assets/lte.png')" contain height="37" />
            </v-toolbar-title>
            <span class="heroText font-weight-bold pa-2">Wallet DeFi API</span>

            <v-spacer></v-spacer>

            <!-- <v-btn
                color="#1f73b7"
                text
                class="d-md-flex hidden-md-and-down d-sm-none d-xs-none link"
                to="/login"
            >
                Submit a request
            </v-btn>
            <v-btn
                icon
                class="d-md-none d-xl-none d-lg-none"
                color="#1F73B7"
                @click="toggleview()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    focusable="false"
                    viewBox="0 0 16 16"
                    class="icon-menu"
                >
                    <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        d="M1.5 3.5h13m-13 4h13m-13 4h13"
                    ></path>
                </svg>
            </v-btn>

            <v-btn color="#1f73b7" text class="link" to="/login">
                Sign In
            </v-btn> -->
        </v-app-bar>

        <div style="padding-top: 70px" v-if="dialog">
            <v-card color="white" class="elevation-5 pa-3">
                <v-btn color="#1f73b7" text class="link" to="/login">
                    Submit a request
                </v-btn>
            </v-card>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        toggleview() {
            this.dialog = !this.dialog;
        },
    },
};
</script>
<style>
* {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
        sans-serif;
}
.link {
    font-size: 14px !important;
    text-transform: none !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
        sans-serif;
    font-weight: 400 !important;
}
#title {
    color: rgba(18, 115, 234, 1);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
        sans-serif;
    font-size: 15px;
    font-weight: 400 !important;
    line-height: 1.5;
    margin: 0 10px;
}
</style>
